import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Connection, ConnectionStatus, ConnectionStatusUpdate, NewConnection } from '../model/connection';
import { ReturnedError } from '../model/error';
import { Observable, Subject, of } from 'rxjs';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private csvDownloadSubject = new Subject<string | any>();
  public csvDownloadObservable$: Observable<string | any> = this.csvDownloadSubject;
  constructor(private apiService: ApiService, private cacheService: CacheService) {}

  getConnection(connectionID: string) {
    return this.apiService.get<Connection>(`ddq/connections/${connectionID}`);
  }

  getAllConnections() {
    if (this.cacheService.getData().allConnections) {
      return of(this.cacheService.getData().allConnections);
    } else {
      return this.apiService.get<Connection[]>(`ddq/connections`);
    }
  }

  getMyConnections() {
    return this.apiService.get<Connection[]>('ddq/connections/distributor');
  }

  getMyPendingConnections(distributorId) {
    return this.apiService.get<Connection[]>(`ddq/connections/distributor/pending/${distributorId}`);
  }

  getMyNonPendingConnections(distributorId) {
    return this.apiService.get<Connection[]>(`ddq/connections/distributor/non-pending/${distributorId}`);
  }

  getMyAcceptedConnections() {
    return this.apiService.get<Connection[]>('ddq/connections/distributor/accepted');
  }

  getConnectionsForDistributor(distributorID: string): Observable<Connection[]> {
    return this.apiService.get<Connection[]>(`ddq/connections/distributor/${distributorID}`);
  }

  getConnectionsForDistributorAndTemplate(distributorID: string, template: string): Observable<Connection[]> {
    return this.apiService.get<Connection[]>(`ddq/connections/distributor/${distributorID}/template/${template}`);
  }

  getOfflineConnectionsForDistributor(distributorID: string): Observable<Connection[]> {
    return this.apiService.get<Connection[]>(`ddq/connections/distributor/${distributorID}/offline`);
  }

  updateContactInConnection(salesContactID: string, connectionID: string) {
    return this.apiService.patch<any, any>(`ddq/connections/${connectionID}/contact/${salesContactID}`, null);
  }

  updateConnectionLastActivityDate(date: string, connectionID: string) {
    return this.apiService.patch<any, any>(`ddq/connections/${connectionID}/amend-date/${date}`, null);
  }

  updateConnectionCreationDate(date: string, connectionID: string) {
    return this.apiService.patch<any, any>(`ddq/connections/${connectionID}/createdat/${date}`, null);
  }

  updateConnectionStatus(connectionId: string, connectionStatus: ConnectionStatus) {
    return this.apiService.patch<ConnectionStatusUpdate, Connection | ReturnedError>(
      `ddq/connections/${connectionId}`,
      {
        status: connectionStatus,
      } as ConnectionStatusUpdate
    );
  }

  doesConnectionExist(distributorID, issuerID, template): Observable<boolean> {
    return this.apiService.patch<{ distributorID: string; issuerID: string; template: string }, boolean>(
      'ddq/connections/existence-check',
      {
        distributorID,
        issuerID,
        template,
      }
    );
  }

  createConnection(
    distributorid: string,
    issuerid: string,
    issuersalescontactid: string,
    template: string,
    subscriber
  ) {
    this.apiService
      .post<NewConnection, Connection | ReturnedError>('ddq/connections', {
        distributorid,
        issuerid,
        issuersalescontactid,
        template,
      } as NewConnection)
      .subscribe(subscriber);
  }

  async searchConnectionsByDistributorIdAndIssuerIdPromise(distributorId, issuerId, template) {
    return await this.apiService
      .get(`ddq/connections/distributor/${distributorId}/issuer/${issuerId}/template/${template}`)
      .toPromise();
  }

  updateExpDate(id, date): Observable<any> {
    return this.apiService.patch(`ddq/connections/${id}/expiration-date/${date}`, null);
  }

  updateRenewalDate(id, date): Observable<any> {
    return this.apiService.patch(`ddq/connections/${id}/last-renewal-date/${date}`, null);
  }

  updateConnectionChaserStartDate(id, date): Observable<any> {
    return this.apiService.patch(`ddq/connections/${id}/connection-chaser-date/${date}`, null);
  }

  getExpDate(id): Observable<any> {
    return this.apiService.get(`ddq/connections/${id}/expiration-date`);
  }

  renewExpDate(id): Observable<any> {
    return this.apiService.patch(`ddq/connections/${id}/expiration-renew`, null);
  }

  isRenewable(id): Observable<any> {
    return this.apiService.get(`ddq/connections/${id}/renewable`);
  }

  resetResponse(id): Observable<any> {
    return this.apiService.patch(`ddq/connections/${id}/reset-response`, null);
  }

  logLastRenewalDate(id): Observable<any> {
    return this.apiService.patch(`ddq/connections/${id}/last-renewal-date`, null);
  }

  notifyIssuer(id): Observable<any> {
    return this.apiService.patch(`ddq/connections/${id}/notify-issuer`, null);
  }
  confirmRenewIssuer(id) {
    return this.apiService.patch(`ddq/connections/${id}/issuer-confirmed-renew`, null);
  }

  declineRenewIssuer(id) {
    return this.apiService.patch(`ddq/connections/${id}/issuer-declined-renew`, null);
  }

  notifyDistributor(id): Observable<any> {
    return this.apiService.patch(`ddq/connections/${id}/notify-distributor`, null);
  }

  confirmRenewDistributor(id) {
    return this.apiService.patch(`ddq/connections/${id}/distributor-confirmed-renew`, null);
  }

  declineRenewDistributor(id) {
    return this.apiService.patch(`ddq/connections/${id}/distributor-declined-renew`, null);
  }

  // Only used by devs
  deleteConnection(connectionID: string) {
    return this.apiService.delete(`ddq/connections/${connectionID}`);
  }

  getRequestersForDistributor(distributorID: string): Observable<any[]> {
    return this.apiService.get<any[]>(`ddq/connections/requesters/${distributorID}`);
  }

  setDownloadCsvSubject(value: string | any): void {
    this.csvDownloadSubject.next(value);
  }

  startTimer(id) {
    return this.apiService.patch(`ddq/connections/${id}/start-timer`, null);
  }

  removeTemplate(id) {
    return this.apiService.patch(`ddq/connections/${id}/remove-template`, null);
  }
}
