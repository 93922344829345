import { Issuer } from './kyd/model/issuer';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { AdminGuard } from './guards/admin-guard.service';
import { InspireGuard } from './guards/inspire-guard.service';
import { DistributorGuard } from './guards/distributor-guard.service';
import { IssuerGuard } from './guards/issuer-guard.service';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './views/terms-of-use/terms-of-use.component';
import { DataProtectionAddendumComponent } from './views/data-protection-addendum/data-protection-addendum.component';
const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  // ...any other options you'd like to use
};
const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AdminGuard],
    canActivate: [AdminGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./kyd/profile/profile.module').then((m) => m.ProfileModule),
    canLoad: [DistributorGuard],
    canActivate: [DistributorGuard],
  },
  {
    path: 'distributors',
    loadChildren: () => import('./kyd/distributors/list-distributors.module').then((m) => m.ListDistributorsModule),
    canLoad: [InspireGuard],
    canActivate: [InspireGuard],
  },
  {
    path: 'issuer-requests',
    loadChildren: () => import('./kyd/issuer-requests/issuer-requests.module').then((m) => m.IssuerRequestsModule),
    canLoad: [InspireGuard],
    canActivate: [InspireGuard],
  },
  {
    path: 'issuers',
    loadChildren: () => import('./kyd/issuers/issuers.module').then((m) => m.IssuersModule),
    canLoad: [InspireGuard],
    canActivate: [InspireGuard],
  },
  {
    path: 'connections',
    loadChildren: () => import('./kyd/connections/connections.module').then((m) => m.ConnectionsModule),
    canLoad: [InspireGuard],
    canActivate: [InspireGuard],
  },
  {
    path: 'ddq',
    loadChildren: () => import('./kyd/ddq/ddq.module').then((m) => m.DdqModule),
    canLoad: [DistributorGuard],
    canActivate: [DistributorGuard],
  },
  {
    path: 'ddq/:requestID',
    loadChildren: () => import('./kyd/ddq/ddq.module').then((m) => m.DdqModule),
    canLoad: [InspireGuard],
    canActivate: [InspireGuard],
  },
  {
    path: 'issuer-portal/ddq/:requestID',
    loadChildren: () => import('./kyd/ddq/ddq.module').then((m) => m.DdqModule),
    canLoad: [IssuerGuard],
    canActivate: [IssuerGuard],
  },
  {
    path: 'profile/ddq/:requestID',
    loadChildren: () => import('./kyd/ddq/ddq.module').then((m) => m.DdqModule),
    canLoad: [DistributorGuard],
    canActivate: [DistributorGuard],
  },
  {
    path: 'requests',
    loadChildren: () => import('./kyd/kyd-request/kyd-request.module').then((m) => m.KydRequestModule),
    canLoad: [InspireGuard],
    canActivate: [InspireGuard],
  },
  {
    path: 'issuer-portal',
    loadChildren: () => import('./kyd/issuer-portal/issuer-portal.module').then((m) => m.IssuerPortalModule),
    canLoad: [IssuerGuard],
    canActivate: [IssuerGuard],
  },
  {
    path: 'contacts',
    loadChildren: () => import('./kyd/contacts/contacts.module').then((m) => m.ContactsModule),
    canLoad: [InspireGuard],
    canActivate: [InspireGuard],
  },
  {
    path: 'files',
    loadChildren: () => import('./kyd/file-management/file-management.module').then((m) => m.FileManagementModule),
    canLoad: [InspireGuard],
    canActivate: [InspireGuard],
  },
  {
    path: 'files/:id',
    loadChildren: () => import('./kyd/file-management/file-management.module').then((m) => m.FileManagementModule),
    canLoad: [InspireGuard],
    canActivate: [InspireGuard],
  },
  // {
  //   path: 'contacts/distributors',
  //   loadChildren: () =>
  //     import('./kyd/distributor-contacts/distributor-contacts.module').then((m) => m.DistributorContactsModule),
  //   canLoad: [InspireGuard],
  //   canActivate: [InspireGuard],
  // },
  // {
  //   path: 'contacts/issuers',
  //   loadChildren: () => import('./kyd/issuer-contacts/issuer-contacts.module').then((m) => m.IssuerContactsModule),
  //   canLoad: [InspireGuard],
  //   canActivate: [InspireGuard],
  // },

  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
  },
  {
    path: 'data-protection-addendum',
    component: DataProtectionAddendumComponent,
  },
  { path: '', redirectTo: '/requests', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
